import React from 'react'
import style from 'styled-components'
import { FaFacebookSquare, FaInstagram } from 'react-icons/fa'

const StyledSocialBar = style.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  vertical-align: middle;
  max-width: 30em;
  min-width: 15em;
  margin: auto;
  padding-left: 1%;
  padding-right: 1%;
  width: 100%;
`

const StyledSocialIcon = style.a`
  color: var(--footer-text-color);
  font-size: 1.5em;

  &:hover {
    filter: drop-shadow(0 0 8px white);
    transition: all ease-in-out .2s;
  }
`

const socials = [
  {
    name: 'facebook',
    icon: <FaFacebookSquare />,
    link: 'https://facebook.com/Andrea17122000/',
    alt: 'Link to our Facebook page'
  },
  {
    name: 'instagram',
    icon: <FaInstagram />,
    link: 'https://instagram.com/amicidiandrea17',
    alt: 'Link to our Instagram page'
  }
]

const SocialBar = () => (
  <StyledSocialBar>
    {socials.map(social => {
      return (
        <StyledSocialIcon href={social.link}>{social.icon}</StyledSocialIcon>
      )
    })}
  </StyledSocialBar>
)

export default SocialBar
