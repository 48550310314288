import React, { useState } from 'react'
import { Link } from 'gatsby'
import style from 'styled-components'
import tw from 'tailwind.macro'
import MediaQuery from 'react-responsive'
import { FaPlusCircle, FaTimesCircle } from 'react-icons/fa'

const Container = style.div`
  display: flex;
  width: 100%;
  height: 100%;
`
const StyledNavBar = style.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  vertical-align: middle;
  max-width: 70em;
  min-width: 15em;
  margin: auto;
  padding-left: 1%;
  padding-right: 1%;
  width: 100%;
  font-family: sans-serif;
  z-index: 999;
`

const StyledNavLink = style(Link)`
  /* Reset 'a' CSS */
  verical-align: middle;
  ${tw`rounded-full`};
  box-shadow: none;
  text-decoration: none;
  color: var(--navbar-text-color);
  border: .15em solid transparent;
  padding: .3em .7em;
  /*border-radius: 1000em;*/
  border-spacing: .6em;
  background: ${props => props.bgColor || 'transparent'};
  box-shadow: 0 2px 8px ${props => props.bgColor || 'transparent'};

  :active {
    color: var(--navbar-text-color);
  }

  :hover {
    transition: ease 200ms;
    border-color: var(--navbar-text-color);
  }
`

const OpenButton = style.div`
  place-self: flex-end;
  color: var(--navbar-text-color);
  margin: auto 0 auto 0;
  padding: .8em;
  align-self: flex-end;
`

const CloseButton = style.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: .5em;
  color: var(--navbar-text-color);
`

const Popup = style(StyledNavBar)`
  backdrop-filter: blur(2px);
  background: #808080b3;
  position: absolute;
  top: 0;
  right: 0;
  width: auto;
  height: auto;
  max-width: 25em;
  min-height: 8em;
  padding: .3em;
  margin: .3em;
  border-radius: 1em;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(-45deg, #EE7752, #E73C7E, #23A6D5, #23D5AB);
  background-size: 400% 400%;
  animation: Gradient 15s ease infinite;
  @keyframes Gradient {
    0% {
      background-position: 0% 50%
    }
    50% {
      background-position: 100% 50%
    }
    100% {
      background-position: 0% 50%
    }
  }
  box-shadow: 0 0 15px #FFFFFFB3;
`

const NavBar = ({ links }) => {
  const [visible, setVisible] = useState(false)

  const show = event => {
    setVisible(true)
    console.log('Show!')
  }

  const hide = event => {
    setVisible(false)
    console.log('Hide!')
  }

  const HiddenPopup = style(Popup)`
    visibility: ${visible ? 'visible' : 'hidden'};
  `

  // <Container onClick={hide} style={{visibility: visible ? "visible" : "hidden", opacity: visible ? "1" : "0"}} />
  return (
    <Container>
      {/* <MediaQuery minWidth={VHThreshold}>
      {(matches) => matches ? <StyledLogoHoriz horizontal={true}/> : <StyledLogo/>}
    </MediaQuery> */}
      <MediaQuery minWidth={500}>
        <StyledNavBar>
          {links.map(link => {
            return (
              <StyledNavLink to={link.path} bgColor={link.bgColor}>
                {link.name}
              </StyledNavLink>
            )
          })}
        </StyledNavBar>
      </MediaQuery>
      <MediaQuery maxWidth={499}>
        <StyledNavBar>
          {links
            .filter(link => !link.popup)
            .map(link => {
              return (
                <StyledNavLink to={link.path} bgColor={link.bgColor}>
                  {link.name}
                </StyledNavLink>
              )
            })}
        </StyledNavBar>
        <OpenButton onClick={show}>
          <FaPlusCircle style={{ width: '1.5em', height: '1.5em' }} />
        </OpenButton>
        <HiddenPopup onClick={hide}>
          {/* <CloseButton/> */}
          <CloseButton onClick={hide}>
            <FaTimesCircle style={{ width: '1.5em', height: '1.5em' }} />
          </CloseButton>
          {links
            .filter(link => link.popup)
            .map(link => {
              return (
                <StyledNavLink to={link.path} bgColor={link.bgColor}>
                  {link.name}
                </StyledNavLink>
              )
            })}
        </HiddenPopup>
      </MediaQuery>
    </Container>
  )
}

export default NavBar
