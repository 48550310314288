import React from 'react'
import style from 'styled-components'

import NavBar from './navbar'
import SocialBar from './socialbar'

import ColorfulBackground from '../images/colorful_bg.svg'
import ColorfulBackgroundFlip from '../images/colorful_bg_flip.svg'

const StyledHeader = style.header`
  grid-row: header;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  background-image: url(${ColorfulBackground});
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: bottom;
  ${props => (props.colorBg ? '' : 'background: none')};
`

const StyledMain = style.main`
  height: 100%;
  width: 100%;
  max-width: 48em;
  grid-row: main;
  place-self: center center;
  display: flex;
  flex-direction: column;
  place-items: center;
  place-content: center;
`

const StyledFooter = style.footer`
  grid-row: footer;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  background-image: url(${ColorfulBackgroundFlip});
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: top;
  ${props => (props.colorBg ? '' : 'background: none')};
`

const StyledLayout = style.div`
  /* General stuff */
  width: 100vw;
  height: 100vh;
  margin: auto;
  /* Grid stuff */
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: [header] 3em [main] 1fr [footer] 3em;
  align-items: center;
`

export const navbarLinks = [
  { name: 'Home', path: '/', bgColor: 'cornflowerblue' },
  { name: 'Blog', path: '/blog', bgColor: 'lightcoral', popup: true },
  { name: 'Eventi', path: '/events', bgColor: 'orchid' },
  { name: 'Donazioni', path: '/donations', bgColor: 'goldenrod' },
  { name: 'Chi siamo', path: '/about', bgColor: 'lightseagreen', popup: true }
]

const Layout = ({ className, colorful, navBarLinks, children }) => (
  <StyledLayout className={className}>
    <StyledHeader colorBg={colorful}>
      <NavBar links={navBarLinks || navbarLinks} />
    </StyledHeader>
    <StyledMain>{children}</StyledMain>
    <StyledFooter colorBg={colorful}>
      <SocialBar />
    </StyledFooter>
  </StyledLayout>
)

export default Layout
